export default [
    {
        header: 'Services'
    },
    {
        title: 'Users',
        route: 'users-list',
        icon: 'UsersIcon',
    },
    {
        title: 'General Categories',
        route: 'categories-list',
        icon: 'AlignJustifyIcon',
    },
    {
        title: 'Blog',
        icon: 'BoldIcon',
        children: [
            {
                title: 'Blog Categories',
                route: 'blogs-categories-list',
            },
            {
                title: 'Blogs',
                route: 'blogs-list',
            }
        ]
    },
    {
        title: 'Intro Insider',
        route: 'sblog-index',
        icon: 'TrendingUpIcon',
    },

    {
        title: 'Countries',
        route: 'country-index',
        icon: 'GlobeIcon',
    },
    {
        title: 'Contact',
        route: 'contactus-index',
        icon: 'PhoneCallIcon',
    },
    {
        title: 'Subscriptions',
        route: 'subscription-index',
        icon: 'LogInIcon',
    },
    //   {
    //     title: 'Subscriptions',
    //     icon: 'LogInIcon',
    //     children: [
    //         {
    //             title: 'Menu',
    //             route: 'menu-list',
    //         },
    //         {
    //             title: 'Subscription Category',
    //             route: 'subcategory-list',
    //         },
    //         {
    //             title: 'Features',
    //             route: 'subfeatures-list',
    //         }
    //     ]
    // },
    {
        title: 'Companies',
        route: 'company-index',
        icon: 'PenToolIcon',
    },
    {
        title: 'Companies Pay',
        route: 'comapnies-pay-create',
        icon: 'PenToolIcon',
    },
    {
        title: 'Offers',
        route: 'offers-index',
        icon: 'GiftIcon',
    },
    {
        title: 'Partner Directory',
        route: 'partner-index',
        icon: 'UserPlusIcon',
    },
    {
        title: 'Faqs',
        route: 'faq-index',
        icon: 'AlertTriangleIcon',
    },
    {
        title: 'Page Titles',
        route: 'pages-index',
        icon: 'Edit3Icon',
    },
    {
        title: 'Components',
        route: 'components-index',
        icon: 'PackageIcon',
    },
    {
        title: 'Features',
        route: 'features-index',
        icon: 'CommandIcon',
    },
    {
        title: 'App Links',
        route: 'links-index',
        icon: 'Link2Icon',
    },
    {
        title: 'Inspired',
        route: 'inspired-index',
        icon: 'AnchorIcon',
    },
    {
        title: 'Social Media Links',
        route: 'sociallinks-index',
        icon: 'CloudIcon',
    },
    {
        title: 'Client Reviews',
        route: 'reviews-index',
        icon: 'RefreshCcwIcon',
    },
    {
        title: 'Orders',
        route: 'orders-index',
        icon: 'TruckIcon',
    },
    {
        title: 'Cancel Orders',
        route: 'cancel-orders',
        icon: 'XCircleIcon',
    },
    {
        title: 'Redeem Offers',
        route: 'redeem-index',
        icon: 'CoffeeIcon',
    },
    {
        title: 'Email Management',
        route: 'email_management-index',
        icon: 'MailIcon',
    },
    {
        title: 'Affiliate Marketing',
        icon: 'BoldIcon',
        children: [
            {
                title: 'Affiliate Sharing',
                route: 'affiliate-sharing-list',
            },
            {
                title: 'Affiliate Users',
                route: 'affiliate-users-list',
            },
            {
                title: 'Affiliate Transfer',
                route: 'transfer-request-list',
            }
        ]
    },
    {
        title: 'Currency',
        route: 'currency',
        icon: 'TrendingUpIcon',
    },
    {
        title: 'Coupon',
        route: 'coupon',
        icon: 'GiftIcon',
    },
    {
        title: 'Strip Connect Query',
        route: 'StripQuery',
        icon: 'TrendingUpIcon',
    },
]

